import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import wind from "../../Assets/Projects/wind.jpeg";

function WindEnergyCard(props) {
    return (
        <Card className="project-card-view">
            <Card.Img variant="top" src={wind} alt="card-img" />
            <Card.Body>
                <Card.Title>Wind Energy</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                    Wind energy is a clean and renewable energy source. Wind turbines utilize the mechanical energy of the wind to power a generator and produce electricity.
                    <br />
                    <br />
                    Wind energy is a perennial and renewable energy source that promises an endless supply of energy while consuming no fossil fuel.
                </Card.Text>
                {/* <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
            </Button> */}
                {"\n"}
                {"\n"}

                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
                {/* <Button
                    variant="primary"
                    href="https://youtube.com"
                    target="_blank"
                    style={{ marginLeft: "10px" }}
                >

                    <CgWebsite /> &nbsp;
                    {"Demo"}
                </Button> */}

            </Card.Body>
        </Card>
    );
}
export default WindEnergyCard;
