import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
// import pdf from "../../Assets/../Assets/Soumyajit_Behera-BIT_MESRA.pdf";
import { AiOutlineDownload } from "react-icons/ai";
// import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { FcPhone } from 'react-icons/fc';
import { MdContactMail } from 'react-icons/md'
import { BsBuilding } from 'react-icons/bs'
import { FaAddressBook } from 'react-icons/fa'

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const resumeLink =
//   "https://raw.githubusercontent.com/soumyajit4419/portfolio/master/src/Assets/Soumyajit_Behera-BIT_MESRA.pdf";

function ResumeNew() {
    const [width, setWidth] = useState(1200);

    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);



    return (
        <div>
            <Container fluid className="resume-section">
                <Particle />
                <Row style={{ justifyContent: "center", position: "relative" }}>
                    {/* <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button> */}

                    <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                        Contact<strong className="purple"> Us</strong>
                    </h1>


                </Row>

                <Row className="resume">
                    {/* <Document file={resumeLink} className="d-flex justify-content-center">
            <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
          </Document> */}
                    <p >
                        <h4><span className="purple">Dhara energy Private Limited</span> </h4><br />

                        <FaAddressBook />&nbsp; #230, Sharadha Complex, <br />

                        Main, Gokulam 2nd Stage, <br />
                        Mysore-570002, <br />

                        Karnataka, India. <br />
                        <br /><br />
                        <h4><span className="purple">Corporate Office :</span></h4><br />

                        <BsBuilding /> &nbsp; #401,Brigade Rubix,<br />
                        Peenya,Bengaluru-560013,<br />
                        Karnataka,India<br />

                        <FcPhone /> &nbsp;+91 9008562288<br />

                        <MdContactMail /> &nbsp; <a href="mailto: sales@dharaenergy.com">sales@dharaenergy.com</a><br />
                    </p>
                </Row>

                <Row style={{ justifyContent: "center", position: "relative" }}>
                    {/* <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
        >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button> */}
                </Row>
            </Container>
        </div>
    );
}

export default ResumeNew;
