import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import solarWindHybrid from "../../Assets/Projects/solar-wind-hybrid.jpeg";

function SolarWindHybridEnergyCard(props) {
    return (
        <Card className="project-card-view">
            <Card.Img variant="top" src={solarWindHybrid} alt="card-img" />
            <Card.Body>
                <Card.Title>Solar Wind Hybrid Energy</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                    Wind-solar hybrid systems use a combination of renewable energy sources like wind and solar, to generate power in the same space.
                    <br />
                    <br />
                    This system is designed to generate electricity using solar panels and multi megawatt wind turbine generators, which are installed on common land and share the same evacuation system to the ESCOM Substation.
                    <br />
                    <br />
                    The space below the solar panels can be utilized for productive purposes such as agriculture, poultry, cold storage, etc.
                </Card.Text>
                {/* <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
            </Button> */}
                {"\n"}
                {"\n"}

                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
                {/* <Button
                    variant="primary"
                    href="https://youtube.com"
                    target="_blank"
                    style={{ marginLeft: "10px" }}
                >

                    <CgWebsite /> &nbsp;
                    {"Demo"}
                </Button> */}

            </Card.Body>
        </Card>
    );
}
export default SolarWindHybridEnergyCard;
