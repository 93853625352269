import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/200w.gif";

import WhyUsCard from "./WhyUsCard";
import sus from "../../Assets/sustainable_goals.png"
import about1 from "../../Assets/about-1.jpg"
import about2 from "../../Assets/about-2.jpg"
import about3 from "../../Assets/about-3.jpg"
import about4 from "../../Assets/about-4.jpg"

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "0px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "right",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h4>
              <p style={{ color: "rgb(200 133 29)" }}>
                "If working apart we're a force powerful enough to destabilise our planet, surely working together we are powerful enough to save it"{" "}
              </p>
              <footer className="blockquote-footer">Sir David Attenborough, COP26, Glasgow 2021</footer>
            </h4>

          </Col>

          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >

            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Get to know <strong className="purple">Us!</strong>
            </h1>
            <Aboutcard />


          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>


        <Row style={{ justifyContent: "center", padding: "0px" }}>
          <Col
            md={9}
            style={{
              justifyContent: "right",
              paddingTop: "20px",
              paddingBottom: "40px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Why choose<strong className="purple"> Us?</strong>
            </h1>
            <WhyUsCard />
          </Col>

          {/* <Col md={9} style={{ paddingBottom: 20 }}>
            <h1 style={{ fontSize: "2.1em", padding: "50px 30px 0px 30px" }}>
              What we<strong className="purple"> Do</strong>
            </h1>
            <img
              src={contact2}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px" }}
            />
          </Col> */}

          <Col md={9} style={{ paddingBottom: 20 }}>
            <h1 style={{ fontSize: "2.1em", padding: "50px 30px 0px 30px" }}>
              {/* We <strong className="purple"> Expertize</strong> in */}
              Dhara's <strong className="purple"> Techno Commercial Service </strong>
            </h1>
            <img
              src={about3}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px", padding: "10px" }}
            />
            <img
              src={about4}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px", padding: "10px" }}
            />
          </Col>

          <Col md={9} style={{ paddingBottom: 20 }}>
            <h1 style={{ fontSize: "2.1em", padding: "50px 30px 0px 30px" }}>
              How we<strong className="purple"> Work</strong>

            </h1>
            <img
              src={about1}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px", padding: "10px" }}
            />

            <img
              src={about2}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px", padding: "10px" }}
            />
          </Col>


          <Col md={9} style={{ paddingBottom: 20 }}>
            <h1 style={{ fontSize: "2.1em", padding: "50px 30px 0px 30px" }}>
              United Nations <strong className="purple">Sustainable Developmet </strong>Goals
            </h1>
            <img
              src={sus}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px" }}
            />
          </Col>


        </Row>

        {/*         
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack /> */}

        {/* <Github /> */}

      </Container>
    </Container >
  );
}

export default About;
