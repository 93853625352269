import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">

          <p style={{ textAlign: "justify" }}>

            {/* <span className="purple">Climate change </span> is the greatest threat hanging over humanity's future. If the solution for
            emission of greenhouse gases which causes global warming is renewable energy and
            sustainable agriculture,
            then why not combine it? */}

            <span className="purple">Climate change </span> is the greatest threat hanging over humanity's future. If the solution to the emission of greenhouse gases that cause global warming is renewable energy and sustainable agriculture, then why not combine them?
            <br />
            <br />

            {/* Here, at <span className="purple">Dhara Energy</span> we do just that. */}
            We do precisely that at <span className="purple">Dhara Energy</span>.

            <br />
            <br />
            Dhara Energy is a new-age start-up that provides environmentally friendly technology with numerous benefits and strives for ecological sustainability.
            <br />
            <br />

            We set the standard for customer care and satisfaction, and we strive to provide the best service possible to everyone with whom we work.

            <br />
            <br />
            {/* Our core values that we live by, */}
          </p>


          {/* <ul>
            <li className="about-activity">
              <ImPointRight /> Sustainable Development
            </li>
            <li className="about-activity">
              <ImPointRight /> Eco Friendly Technology
            </li>
            <li className="about-activity">
              <ImPointRight /> Renewable Sources of Energy
            </li>
          </ul> */}

          <br />
          {/* 
          <p style={{ color: "rgb(155 126 172)" }}>
            "If working apart we're a force powerful enough to destabilise our planet, surely working together we are powerful enough to save it"{" "}
          </p>
          <footer className="blockquote-footer">Sir David Attenborough, COP26, Glasgow 2021</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
