import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import greenhouse from "../../Assets/Projects/greenhouse.jpeg";

function SolarGreenhouseCard(props) {
    return (
        <Card className="project-card-view">
            <Card.Img variant="top" src={greenhouse} alt="card-img" />
            <Card.Body>
                <Card.Title>Solar Greenhouse</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                    Solar greenhouses are enclosed structures that provide a proper environment for crop, vegetable, or flower growth in all weather conditions.
                    <br />
                    <br />
                    We offer agroponics and hydroponics solutions. These greenhouses can generate electricity, grow crops, and provide low-cost cold storage and processing facilities.
                    <br />
                    <br />
                    They can be built on either land or rooftops.
                </Card.Text>
                {/* <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
            </Button> */}
                {"\n"}
                {"\n"}

                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
                {/* <Button
                    variant="primary"
                    href="https://youtube.com"
                    target="_blank"
                    style={{ marginLeft: "10px" }}
                >

                    <CgWebsite /> &nbsp;
                    {"Demo"}
                </Button> */}

            </Card.Body>
        </Card>
    );
}
export default SolarGreenhouseCard;
