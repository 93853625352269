import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import agrovoltaics from "../../Assets/Projects/agrovoltaics.jpeg";

function AgrovoltaicsCard(props) {
    return (
        <Card className="project-card-view">
            <Card.Img variant="top" src={agrovoltaics} alt="card-img" />
            <Card.Body>
                <Card.Title>Agrovoltaics</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                    Since solar panels have been mounted on the ground all over the world, there have been a number of operational issues.
                    <br />
                    <br />
                    Dhara Energy promotes the Principle of Agrovoltaics, in which solar panels coexist with crops on the same plot of land.
                    <br />
                    <br />
                    Today, it is clear that such a method of true solar farming has more potential to benefit society and business than it has ever had before through good return on investment.
                </Card.Text>
                {/* <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
            </Button> */}
                {"\n"}
                {"\n"}

                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
                {/* <Button
                    variant="primary"
                    href="https://youtube.com"
                    target="_blank"
                    style={{ marginLeft: "10px" }}
                >

                    <CgWebsite /> &nbsp;
                    {"Demo"}
                </Button> */}

            </Card.Body>
        </Card>
    );
}
export default AgrovoltaicsCard;
