import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function WhyUsCard() {
    return (
        <Card className="quote-card-view">
            <Card.Body>
                <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "justify" }}>


                        {/* Our company's core belief lies in providing solutions which are <span className="purple">ecologically sustainable and
                            customer friendly.</span> */}

                        Our company's core belief is in providing environmentally sustainable and customer-friendly solutions.

                        <br />
                        <br />

                        {/* Long term vision is to become a <span className="purple">reliable provider of Energies</span> which are most efficient and
                        Environment friendly. */}

                        Long-term goals include becoming a dependable provider of energy that is both efficient and environmentally friendly.

                        <br />
                        <br />
                        {/* We not only understand the problems related to the present scenario relating to energy crisis,

                        but also have <span className="purple">experience and expertise to optimize natural resources</span> as cleaner options */}

                        We not only understand the issues surrounding the current energy crisis, but we also have the experience and expertise to optimise natural resources as cleaner alternatives.

                        <br />
                        <br />

                        You benefit greatly from a high return on investment, high quality materials and workmanship, and efficient operations with low downtime and high productivity.
                        {/* Our core values that we live by, */}
                    </p>


                    {/* <ul>
                        <li className="about-activity">
                            <ImPointRight /> Sustainable Development
                        </li>
                        <li className="about-activity">
                            <ImPointRight /> Eco Friendly Technology
                        </li>
                        <li className="about-activity">
                            <ImPointRight /> Renewable Sources of Energy
                        </li>
                    </ul>

                    <br />

                    <p style={{ color: "rgb(155 126 172)" }}>
                        "If working apart we're a force powerful enough to destabilise our planet, surely working together we are powerful enough to save it"{" "}
                    </p>
                    <footer className="blockquote-footer">Sir David Attenborough, COP26, Glasgow 2021</footer> */}
                </blockquote>
            </Card.Body>
        </Card>
    );
}

export default WhyUsCard;
