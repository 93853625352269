import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

import AgrovoltaicsCard from "../Projects/AgrovoltaicsCard"
import FloatingSolarCard from "../Projects/FloatingSolarCard"
import RooftopSolarCard from "../Projects/RooftopSolarCard"
import SolarGreenhouseCard from "../Projects/SolarGreenhouseCard"
import SolarWindHybridEnergyCard from "../Projects/SolarWindHybridEnergyCard"
import WindEnergyCard from "../Projects/WindEnergyCard"

// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Products </strong>
        </h1>
        <p style={{ color: "black" }}>
          Here are a few cutting edge technologies that we use to reach our vision.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <SolarGreenhouseCard />
          </Col>

          <Col md={4} className="project-card">
            <RooftopSolarCard />
          </Col>

          <Col md={4} className="project-card">
            <AgrovoltaicsCard />
          </Col>

          <Col md={4} className="project-card">
            <SolarWindHybridEnergyCard />
          </Col>

          <Col md={4} className="project-card">
            <WindEnergyCard />
          </Col>

          <Col md={4} className="project-card">
            <FloatingSolarCard />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
